
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PushNotificationStatus from "@/components/Notifications/PushNotificationStatus.vue";
import { ArbitrageHandler } from "@/service/arbitrage-checker/ArbitrageHandler";
import { mdiClockOutline, mdiCog, mdiShieldCheck } from "@mdi/js";

@Component({
  components: { PushNotificationStatus },
})
export default class TierSelector extends Vue {
  @Prop()
  arbitrageHandler?: ArbitrageHandler;

  @Prop()
  showOnlyProfitableItems?: boolean;

  @Prop()
  showExchangeStatus?: boolean;

  menu = false;

  icons = {
    mdiCog: mdiCog,
    mdiClockOutline: mdiClockOutline,
    mdiShieldCheck: mdiShieldCheck,
  };

  // destroyArbi() {
  //   this.menu = false;
  //   this.arbitrageHandler?.destroy();
  // }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
