
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Dictionary } from "ccxt";
import { TierInfo } from "@/views/Arbitrage.vue";
import TierSelectorTile from "@/components/Arbitrage/TierSelectorTile.vue";

@Component({
  components: { TierSelectorTile },
})
export default class TierSelector extends Vue {
  @Prop()
  selectedTier?: number;

  @Prop()
  tierInfos?: Dictionary<TierInfo>;
}
