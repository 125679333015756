var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-center detailed-profit px-4",class:[
    _vm.item.bestArbitrage && _vm.item.bestArbitrage.profit >= _vm.minimumProfit
      ? _vm.item.tier === 4
        ? 'green darken-1 white--text'
        : _vm.item.tier === 3
        ? 'green white--text'
        : _vm.item.tier === 2
        ? 'light-green darken-1 white--text'
        : _vm.item.tier === 1
        ? 'orange darken-1 white--text'
        : ''
      : '',
  ]},[(_vm.item.bestArbitrage)?[(_vm.item.bestArbitrage.profit >= _vm.minimumProfit)?[_c('div',{staticStyle:{"cursor":"pointer","padding-top":"2px"},on:{"click":function($event){return _vm.$emit('openItem', _vm.item.currency)}}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"pt-1",staticStyle:{"font-size":"16px"}},[_vm._v("+"+_vm._s(_vm.item.bestArbitrage.profitPercent.toFixed(1))+"%")]),_c('div',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.item.bestArbitrage.profit.toFixed())+" USD")])]),_c('div',{staticClass:"text-right",staticStyle:{"font-style":"italic","font-size":"13px","margin-top":"-2px","opacity":"0.7"}},[_vm._v(" capital: "+_vm._s(_vm.item.bestArbitrage.capital)+" USD ")])])]:[_vm._v("-")],_c('div',{staticClass:"hide-item-btn",on:{"click":function($event){return _vm.$emit('hideItem', _vm.item)}}},[_c('v-icon',{staticClass:"icon"},[_vm._v(_vm._s(_vm.icons.mdiCloseCircle))])],1)]:_c('div',[_c('v-btn',{staticStyle:{"width":"100%","font-size":"12px","height":"47px"},attrs:{"elevation":"0","tile":"","text":_vm.item.tickerBuy.profitPercent > 2 ? undefined : true,"color":_vm.item.tickerBuy.profitPercent > 2 ? 'primary' : ''},on:{"click":function($event){return _vm.$emit('showItem', _vm.item)}}},[_vm._v(" show ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }