
import Vue from "vue";
import Component from "vue-class-component";
import { ArbitrageHandler } from "@/service/arbitrage-checker/ArbitrageHandler";
import { ArbitrageItem } from "@/service/arbitrage-checker/ArbitrageTypes";
import CcxtPrivate from "@/service/CcxtPrivate";
import { Watch } from "vue-property-decorator";
import { Dictionary } from "ccxt";
import DetailsMenu from "@/components/Arbitrage/DetailsMenu.vue";
import TierSelector from "@/components/Arbitrage/TierSelector.vue";
import ArbitrageItemTable from "@/components/Arbitrage/ArbitrageItemTable.vue";
import BoringMarkets from "@/components/Arbitrage/BoringMarkets.vue";
import ExchangeStatus from "@/components/Arbitrage/ExchangeStatus.vue";
import TierSelectorMobile from "@/components/Arbitrage/TierSelectorMobile.vue";
import ArbitrageItemList from "@/components/Arbitrage/ArbitrageItemList.vue";

export interface TierInfo {
  tier: number;
  count: number;
  profitSum: number;
}

@Component({
  components: {
    ArbitrageItemList,
    TierSelectorMobile,
    ExchangeStatus,
    BoringMarkets,
    ArbitrageItemTable,
    TierSelector,
    DetailsMenu,
  },
})
export default class Arbitrage extends Vue {
  arbitrageHandler?: ArbitrageHandler;
  items: Array<ArbitrageItem> = [];
  showOnlyProfitableItems = true;
  tickersRoom = "tickers";
  minimumProfit = 1;
  selectedTier: number | null = 3;

  tierInfos: Dictionary<TierInfo> = {};

  showExchangeStatus = false;

  @Watch("showOnlyProfitableItems")
  onShowOnlyProfitableItems() {
    this.arbitrageHandler?.setShowOnlyProfitableItems(this.tickersRoom, this.showOnlyProfitableItems);
  }

  get itemsSorted() {
    this.tierInfos = {};
    for (let i = 1; i <= 3; i++) {
      this.tierInfos[i] = { tier: i, count: 0, profitSum: 0 };
    }

    return this.items
      .filter((item) => {
        let tier = item.tier;
        if (tier === 4) {
          tier = 3;
        }
        const minimumProfitPass =
          !this.showOnlyProfitableItems || (item.bestArbitrage?.profit ?? 0) > this.minimumProfit;

        if (minimumProfitPass) {
          this.tierInfos[tier].count++;
          this.tierInfos[tier].profitSum += item.bestArbitrage?.profit ?? 0;
        }

        return (this.selectedTier === null || tier === this.selectedTier) && minimumProfitPass;
      })
      .sort((a, b) => {
        const bestProfitA = a.bestArbitrage?.profit ?? 0;
        const bestProfitB = b.bestArbitrage?.profit ?? 0;

        if (bestProfitA >= this.minimumProfit && bestProfitB >= this.minimumProfit) {
          if (a.tier > b.tier) {
            return -1;
          }
          if (b.tier > a.tier) {
            return 1;
          }
          return bestProfitB - bestProfitA;
        }

        if (bestProfitA >= this.minimumProfit) {
          return -1;
        }

        if (bestProfitB >= this.minimumProfit) {
          return 1;
        }

        return (b.tickerBuy?.profitPercent ?? 0) - (a.tickerBuy?.profitPercent ?? 0);
      });
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  async created() {
    this.arbitrageHandler = new ArbitrageHandler();
    await this.arbitrageHandler.start();
    this.arbitrageHandler.joinRoom("tickers");
    this.onShowOnlyProfitableItems();

    this.arbitrageHandler.items$.subscribe((items) => {
      this.items = items;
    });

    //const exchanges = ccxt.exchanges.filter((exchange) => exchange !== "poloniex");
    const exchanges: string[] = [];
    for (const exchange of exchanges) {
      const ccxtExchange = new CcxtPrivate(exchange, true);
      if (exchange === "okx") {
        ccxtExchange.ccxt.apiKey = "d9f65351-8798-4dbd-ac51-8ca0bfbd3d58";
        ccxtExchange.ccxt.secret = "A06CCED4D12A65DF7B9477D74F5B5248";
        ccxtExchange.ccxt.password = ",R@pa4thp+DsVAb";
      }
      if (exchange === "binance") {
        ccxtExchange.ccxt.apiKey = "XHvyEn2uPNerxMTqUbYYGdw6KMkI8sMagSwrHcrEFXFYO3RfpiWz8ytguO5TR1yg";
        ccxtExchange.ccxt.secret = "ZfxFiJET9yNSAR7FvKVoGdZjyJRzCwvgqcydByE9aTl6ZZA9oAoeJGn8UCa06BK5";
      }
      if (exchange === "kucoin") {
        ccxtExchange.ccxt.apiKey = "62bb137ebd791200019a12ca";
        ccxtExchange.ccxt.secret = "4ce6f0e0-4bdc-4f44-bc19-5849e9f85cd5";
        ccxtExchange.ccxt.password = "J$sBCkEk6TBQzpn";
      }
      if (exchange === "gateio") {
        ccxtExchange.ccxt.apiKey = "a3d5f0f150f5bb3bb943e8017d75bd13";
        ccxtExchange.ccxt.secret = "4422851aa4bcf7c98f637794ca50da56bcb993685a180734d993c753e777e32f";
      }
      //ccxtExchange.ccxt.publicGetCoins = ccxtExchange.ccxt.publicGetWalletCoins;
      ccxtExchange.loadMarkets().then(async () => {
        //console.log(exchange, ccxtExchange.ccxt.currencies);
        //console.log(exchange, ccxtExchange.ccxt.markets);
        this.testCurrencies(exchange, ccxtExchange).then();
      });
    }
    exchanges.push("");
  }

  destroyed() {
    this.arbitrageHandler?.stop();
  }

  private async testCurrencies(exchange: string, ccxtExchange: CcxtPrivate) {
    if (exchange === "") {
      console.log(exchange, ccxtExchange);
    }
    //if (exchange === "okx") {
    //  console.log("TEST", "okx", ccxtExchange.ccxt.currencies);
    //}
    // if (exchange === "kucoin") {
    //   console.log("TEST", exchange, ccxtExchange.ccxt.fetchTransactionFee("POSI"));
    // }
    // if (exchange === "aax" || exchange === "gateio") {
    //   const transactionFees = await ccxtExchange.ccxt.fetchTransactionFees();
    //   const withdrawPercent = transactionFees.withdrawPercent;
    //   const results: Record<string, number> = {};
    //   for (const [currencyCode, feePercent] of Object.entries(withdrawPercent ?? {})) {
    //     if (feePercent !== undefined && feePercent > 0) {
    //       results[currencyCode] = feePercent;
    //     }
    //   }
    //   console.log(results);
    // }
    // const r1 = await ccxtExchange.ccxt.privateMarginGetBorrowable({ currency: "BTC" });
    // const r2 = await ccxtExchange.ccxt.privateMarginGetCrossBorrowable({ currency: "SOL" });
    // console.log(r1, r2);
    // const margins: string[] = [];
    // const crossMargins: string[] = [];
    // const isolatedMargins: string[] = [];
    // for (const market of Object.values(ccxtExchange.ccxt.markets)) {
    //   if (market.margin) {
    //     margins.push(market.symbol.replace("/", ""));
    //   }
    //   if (market.crossMargin) {
    //     crossMargins.push(market.symbol.replace("/", ""));
    //   }
    //   if (market.isolatedMargin) {
    //     isolatedMargins.push(market.symbol.replace("/", ""));
    //   }
    // }
    // console.log("******", exchange, "Margins", margins);
    //console.log(exchange, "Cross Margins", crossMargins);
    //console.log(exchange, "Isolated Margins", isolatedMargins);
    // ccxtExchange.ccxt.sapiGetMarginAllPairs().then((data: any) => {
    //   console.log(
    //     exchange,
    //     "CROSS MARGIN",
    //     data.filter((item) => item.isSellAllowed === false)
    //   );
    // });
    // ccxtExchange.ccxt.sapiGetMarginIsolatedAllPairs().then((data: any) => {
    //   console.log(
    //     exchange,
    //     "ISOLATED MARGIN",
    //     data.filter((item) => item.isSellAllowed === false)
    //   );
    // });
    //ccxtExchange.ccxt.fetchTransactionFees().then((data: any) => {
    //  console.log(data);
    //});
    // ccxtExchange.ccxt.privateWalletGetWithdrawStatus().then((data: any) => {
    //   console.log(data);
    //   console.log(data.filter((item) => item.currency === "BRISE"));
    //   console.log(data.filter((item) => item.currency.indexOf("_") !== -1));
    // });
    // ccxtExchange.ccxt
    //   .fetchCurrencies({
    //     includeMultiChainCurrencies: true,
    //     includeCurrencyConfigurations: true,
    //   })
    //   .then((data: any) => {
    //     console.log(data);
    //   });
    //if (exchange === "kucoin") {
    //  ccxtExchange.ccxt.extendCurrency("BTC").then(() => {
    //    console.log(ccxtExchange.ccxt.currencies["BTC"]);
    //  });
    //  ccxtExchange.ccxt.extendCurrency("FRA").then(() => {
    //    console.log(ccxtExchange.ccxt.currencies["FRA"]);
    //  });
    //}
    //
    // const test = async () => {
    //   const loop = true;
    //   while (loop) {
    //     const ticker = await ccxtExchange.ccxt.watchTicker("GST/USDT");
    //     console.log("phemex", "GST/USDT", ticker);
    //   }
    // };
    // test();
    // for (const market of Object.values(ccxtExchange.ccxt.markets)) {
    //   if (market.active && market.spot && MarketUtils.isStableCoinQuoteMarket(market)) {
    // setInterval(() => {
    //   ccxtExchange.ccxt.watchOrderBook("LUNC/USDT").then((data) => {
    //     console.log("LUNC/USDT", data);
    //   });
    // }, 1000);
    //   }
    // }
    //const networks = new Set();
    //for (const currency of Object.values(ccxtExchange.ccxt.currencies)) {
    //for (const network of Object.values(currency.networks)) {
    //  networks.add(network.network + " @ " + currency.code);
    //console.log(currency.code, currency.networks !== undefined ? Object.keys(currency.networks) : undefined);
    //}
    //}
    //console.log([...networks].sort());
    //if (exchange === "kucoin") {
    //  ccxtExchange.ccxt.publicGetCurrenciesCurrency({ currency: "ACA" }).then((data: any) => {
    //    console.log("kucoin", "ACA", data);
    //  });
    //}
    // if (exchange === "okx") {
    //
    // }
    //if (exchange === "poloniex" || exchange === "binance") {
    //  console.log(exchange, ccxtExchange.ccxt.currencies["CREAM"]);
    //}
  }
}
