
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ArbitrageItem } from "@/service/arbitrage-checker/ArbitrageTypes";
import ArbitrageSide from "@/components/Arbitrage/ArbitrageSide.vue";
import CurrencyNameInfo from "@/components/Arbitrage/CurrencyNameInfo.vue";
import NetworkInfo from "@/components/Arbitrage/NetworkInfo.vue";
import WithdrawFee from "@/components/Arbitrage/WithdrawFee.vue";
import MarginInfo from "@/components/Arbitrage/MarginInfo.vue";
import dayjs from "dayjs";
import { ArbitrageHandler } from "@/service/arbitrage-checker/ArbitrageHandler";
import ArbitrageItemProfit from "@/components/Arbitrage/ArbitrageItemProfit.vue";

@Component({
  components: {
    ArbitrageItemProfit,
    MarginInfo,
    WithdrawFee,
    NetworkInfo,
    CurrencyNameInfo,
    ArbitrageSide,
  },
})
export default class ArbitrageItemTable extends Vue {
  @Prop()
  items?: ArbitrageItem[];

  @Prop()
  minimumProfit?: number;

  @Prop()
  arbitrageHandler?: ArbitrageHandler;

  selectedArbitrageItem: ArbitrageItem | null = null;

  timer?: number;
  currentTime = Date.now();

  async created() {
    this.timer = setInterval(() => {
      this.currentTime = Date.now();
    }, 1000);
  }

  openItem(currencyCode: string) {
    const url = this.getArbitrageItemUrl(currencyCode);

    window.open(url, "_blank");
  }

  getArbitrageItemUrl(currencyCode: string) {
    let routeData = this.$router.resolve({ name: "arbitrageItem", params: { currencyCode } });
    return routeData.href;
  }

  showItem(item: ArbitrageItem) {
    this.arbitrageHandler?.showItem(item);
  }

  hideItem(item: ArbitrageItem) {
    this.arbitrageHandler?.hideItem(item);
  }

  dateFormat(date?: number) {
    if (date === undefined) {
      return "-";
    }

    const diffMs = this.currentTime - date;
    if (diffMs < 3000) {
      return "live";
    }

    const diffSec = dayjs().diff(date, "seconds");
    return diffSec + " second(s) ago";
  }

  itemDateClass(itemDate?: number) {
    if (itemDate === undefined) {
      return;
    }
    const diffSec = (this.currentTime - itemDate) / 1000;
    if (diffSec > 300) {
      return "red lighten-3";
    } else if (diffSec > 60) {
      return "orange lighten-3";
    } else if (diffSec > 30) {
      return "yellow lighten-3";
    }
  }

  destroyed() {
    if (this.timer !== undefined) {
      clearInterval(this.timer);
    }
  }
}
