
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ArbitrageItem } from "@/service/arbitrage-checker/ArbitrageTypes";

@Component({})
export default class ArbitrageItemBuyTd extends Vue {
  @Prop()
  item?: ArbitrageItem;

  @Prop()
  minimumProfit?: number;

  get pre() {
    return (this.item?.bestArbitrage?.profit ?? 0) >= 0 ? "+" : "-";
  }
}
