
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TierInfo } from "@/views/Arbitrage.vue";

@Component({})
export default class TierSelector extends Vue {
  @Prop()
  selected?: boolean;

  @Prop()
  tierInfo?: TierInfo;
}
