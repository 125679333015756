
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ArbitrageItem } from "@/service/arbitrage-checker/ArbitrageTypes";
import { mdiCloseCircle } from "@mdi/js";

@Component({})
export default class ArbitrageItemBuyTd extends Vue {
  @Prop()
  item?: ArbitrageItem;

  @Prop()
  minimumProfit?: number;

  icons = {
    mdiCloseCircle: mdiCloseCircle,
  };
}
