
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { PushRegister } from "@/service/PushRegister";
import { mdiAlertCircleOutline } from "@mdi/js";

@Component({})
export default class PushNotificationStatus extends Vue {
  @Prop()
  label?: string;

  hasPushSubscription = false;
  errorDialog = false;
  errorMessage = "";

  icons = {
    mdiAlertCircleOutline: mdiAlertCircleOutline,
  };

  @Watch("hasPushSubscription", { immediate: true })
  async onHasPushSubscription(newVal: boolean, oldVal?: boolean) {
    if (oldVal === undefined) {
      PushRegister.getPushSubscription().then((pushSubscription) => {
        this.hasPushSubscription = pushSubscription !== null;
      });
      return;
    }

    if (this.hasPushSubscription) {
      try {
        await PushRegister.subscribe();
      } catch (e: any) {
        if (e.message === "permissionNotGranted") {
          e.message = "Please allow push notifications in your browser";
          this.hasPushSubscription = false;
        }
        this.errorMessage = e.message;
        this.errorDialog = true;
      }
    } else {
      await PushRegister.unsubscribe();
    }
  }
}
